<template>
  <v-container fluid tag="section">
    <validation-observer ref="observer" slim v-slot="{ invalid }">
      <v-row>
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-clipboard-outline"
            title="Consultar Aprovações"
            inline
            class="px-5 py-4 ml-0"
          >
            <v-row class="mt-5">
              <v-col class="pb-0 pt-1" cols="6">
                <input-month
                  :minMonth="'2023-01'"
                  label="Competência de*"
                  rules="required|min:7"
                  @monthSelected="setCompetenciaDe"
                  :selectedMonth="competencia_de"
                />
              </v-col>
              <v-col class="pb-0 pt-1" cols="6">
                <input-month
                  :minMonth="'2023-01'"
                  label="Competência até*"
                  rules="required|min:7"
                  @monthSelected="setCompetenciaAte"
                  :selectedMonth="competencia_ate"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  id="btn-consulta-detalhada"
                  color="primary"
                  dark
                  elevation="1"
                  relative
                  text
                  medium
                  style="float: right"
                  :disabled="invalid"
                  :loading="downloadingConsulta"
                  @click="baixarConsultaDetalhada()"
                >
                  <v-icon left size="30px">
                    mdi-microsoft-excel
                  </v-icon>
                  Consulta Detalhada
                </v-btn>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12">
                <v-expansion-panels
                  v-model="panels"
                  accordion
                  multiple
                >
                  <v-expansion-panel v-for="base in bases" :key="base.value">
                    <v-expansion-panel-header>
                      <strong>{{ base.text }}</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table 
                        class="data-table" 
                        multi-sort 
                        dense 
                        :headers="headers" 
                        :items="items[base.value]" 
                        :options="{ itemsPerPage: -1 }" 
                        :headerProps="{ sortByText: 'Ordenar Por' }"
                        no-data-text="Nenhum registro disponível" 
                        no-results-text="Nenhum registro correspondente encontrado"
                        :mobile-breakpoint="10"
                      >
                        <template v-slot:[`item.perc_aprovados`]="{ item }">
                          <span
                            :class="item.perc_aprovados === 100 ? 'success--text font-weight-bold' : 'error--text font-weight-bold'"
                          >
                            {{ item.perc_aprovados | parseNumberToFloatBrIgnoringNaN }} %
                          </span>
                        </template>
                        <template v-slot:[`item.perc_reprovados`]="{ item }">
                          <span
                            :class="item.perc_reprovados === 0 ? 'success--text font-weight-bold' : 'error--text font-weight-bold'"
                          >
                            {{ item.perc_reprovados | parseNumberToFloatBrIgnoringNaN }} %
                          </span>
                        </template>
                        <template v-slot:[`item.perc_nao_validados`]="{ item }">
                          <span
                            :class="item.perc_nao_validados === 0 ? 'success--text font-weight-bold' : 'info--text font-weight-bold'"
                          >
                            {{ item.perc_nao_validados | parseNumberToFloatBrIgnoringNaN }} %
                          </span>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </validation-observer>
    <v-overlay :value="loading" :opacity="0.85">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </v-overlay>
  </v-container>
</template>

<script>

import ConsultaAprovacoesTemasService from "@/services/ConsultaAprovacoesTemasService.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "ConsultaAprovacoesTemasIndex",
  components: {
    InputMonth: () => import("@/components/general/InputMonth.vue"),
  },
  data: () => ({
    loading: false,
    downloadingConsulta: false,
    panels: [0,1,2],
    bases: [
      { "text": "COMERCIAL", value: "comercial" },
      { "text": "SERVIÇOS", value: "servicos" },
      { "text": "TÉCNICOS", value: "tecnicos" }
    ],
    headers: [
      {
        text: "Competência",
        value: "competencia",
      },
      {
        text: "Tema",
        value: "tema",
      },
      {
        text: "Aprovados",
        value: "perc_aprovados",
      },
      {
        text: "Reprovados",
        value: "perc_reprovados",
      },
      {
        text: "Não Validados",
        value: "perc_nao_validados",
      }
    ],
    items: {},
  }),
  computed: {
    ...mapState("parametrosConsultaAprovacoesTemas", [
      "competencia_de",
      "competencia_ate",
    ]),
  },
  mounted() {
    this.consultarAprovacoes();
  },
  methods: {
    ...mapActions("parametrosConsultaAprovacoesTemas", [
      "setCompetenciaDe",
      "setCompetenciaAte",
    ]),
    consultarAprovacoes() {
      this.loading = true;

      ConsultaAprovacoesTemasService.consultar(
        this.competencia_de, 
        this.competencia_ate
      )
        .then(res => {
          this.items = res;
        })
        .catch(err => {
          this.$toast.error('Falha ao consultar aprovações.', '', {
            position: 'topRight',
            timeout: 5000
          });
          throw err;
        })
        .finally(() => this.loading = false);
    },
    baixarConsultaDetalhada() {
      this.downloadingConsulta = true;

      this.$toast.info('Preparando sua consulta para download, essa etapa pode demorar, por favor, aguarde.', '', {
        position: 'topRight',
        timeout: 5000,
      });

      ConsultaAprovacoesTemasService.baixarConsultaDetalhada(
        this.competencia_de, 
        this.competencia_ate,
        { timeout: 60 * 60 * 1000 }
      )
        .then(async res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;

          let nomeArquivo = `Consulta_Aprovacoes_de_Temas-${this.$store.getters.getCompanySigla}-${this.competencia_de}_${this.competencia_ate}`;

          link.setAttribute('download', `${nomeArquivo}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          this.$toast.error('Falha no download do arquivo.', '', {
            position: 'topRight',
            timeout: 5000
          });
          throw err;
        })
        .finally(() => this.downloadingConsulta = false);
    }
  },
  watch: {
    competencia_de() {
      this.consultarAprovacoes();
    },
    competencia_ate() {
      this.consultarAprovacoes();
    },
  }
};
</script>

<style>
.data-table {
  margin: 10px 0;
  padding: 10px;
}
.data-table table tbody tr td {
  font-size: 12px;
  padding: 0px 8px !important;
  text-align: end;
}

.data-table table thead tr th {
  font-size: 12px;
  height: 25px;
  text-align: end !important;
  font-weight: 500;
}
</style>