import APIService from './APIService';

export default {
  consultar(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`consulta-aprovacoes-temas/consultar?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  baixarConsultaDetalhada(competenciaDe, competenciaAte, config) {
    return APIService.apiCall().get(`consulta-aprovacoes-temas/baixar-consulta-detalhada?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`, {
      responseType: 'blob',  
      ...config
    });
  },
}